var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{class:[
    'table__row',
    {
      'table__row-customers': _vm.customers && _vm.customerOption,
      'table__row-customers-option': _vm.customers && !_vm.customerOption,
      'table__row-manage-data': _vm.manageData,
    } ]},[_vm._l((_vm.fields),function(field,i){
  var _obj;
return _c('td',{key:_vm.data[field],class:( _obj = {}, _obj[field] = true, _obj[_vm.data[field] && _vm.data[field].toString().toLowerCase()] = field === 'status', _obj.untitled = field === 'name' && _vm.data[field] === 'Untitled User', _obj['text-capitalize'] =  field === 'country', _obj )},[(i === 0 && !_vm.customers)?_c('span',{staticClass:"checkbox"},[_c('k-checkbox',{attrs:{"name":_vm.data[field],"value":_vm.data['id']},model:{value:(_vm.innerValue),callback:function ($$v) {_vm.innerValue=$$v},expression:"innerValue"}})],1):_vm._e(),((field === 'name' || field === 'fullName') && _vm.customers === true)?_c('span',{staticClass:"image"},[_c('img',{staticClass:"image-item",attrs:{"src":_vm.data.imageUrl || _vm.avatar,"alt":"image"}})]):_vm._e(),(field === 'country')?_c('span',{staticClass:"flag"},[_c('img',{attrs:{"src":("/countries/" + (_vm.data[field].toLowerCase()) + ".svg"),"alt":""}})]):_vm._e(),_c('span',{class:{
            mark: _vm.showStatus && (i === 0),
            published: _vm.data.isPublished,
            unpublished: !_vm.data.isPublished,
            deleted: _vm.data.isDeleted,
          },on:{"click":function($event){return _vm.$emit('clickAction')}}},[_vm._v(" "+_vm._s(_vm._f("formatField")(_vm.data[field],field))+" ")])])}),(_vm.customers === true && _vm.customerOption === true)?_c('td',{staticClass:"view-activity"},[_c('button',{staticClass:"view-activity__btn",on:{"click":function($event){return _vm.$emit('view', _vm.data.id)}}},[_vm._v("View Activity")])]):_vm._e(),(_vm.customers === true && _vm.customerOption === true)?_c('td',{staticClass:"button"},[_c('button',{class:[[_vm.data.status === 'enabled' ? 'disable' : 'enable']],on:{"click":function($event){return _vm.$emit('changeStatus')}}},[_vm._v(" "+_vm._s(_vm.data.status === 'enabled' ? 'Disable' : 'Enable')+" ")])]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }