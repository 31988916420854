export default {
  indicators: [],
  loadedList: [],
  list: [],
  categories: [
    'agriculture',
    'economy',
    'finance',
    'health',
    'labour',
    'monetary',
    'population',
    'tax',
    'trade',
  ],
};
