var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('k-dashboard-layout',[_c('div',{staticClass:"settings"},[_c('aside',{staticClass:"settings__sidebar"},[_c('h1',[_vm._v("Settings")]),_c('ul',{staticClass:"settings__menu"},[_c('li',{class:[
          'settings__menu__item',
          { active: _vm.activeTab === 'profile'}
          ],on:{"click":function($event){_vm.activeTab = 'profile'}}},[_vm._v(" Profile ")]),_c('li',{class:[
          'settings__menu__item',
          { active: _vm.activeTab === 'roles'}
          ],on:{"click":function($event){_vm.activeTab = 'roles'}}},[_vm._v(" Roles & Permissions ")]),_c('li',{class:[
          'settings__menu__item',
          { active: _vm.activeTab === 'manage'}
          ],on:{"click":function($event){_vm.activeTab = 'manage'}}},[_vm._v(" Manage Admins ")])])]),(_vm.activeTab === 'profile')?_c('k-profile'):_vm._e(),(_vm.activeTab === 'roles')?_c('k-roles'):_vm._e(),(_vm.activeTab === 'manage')?_c('k-manage'):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }