export default {
  token: null,
  user: {
    firstName: '',
    lastName: '',
    email: '',
    role: '',
  },
  profile: {},
};
