<template>
  <svg
    width="16"
    height="12"
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    focusable="false"
  >
    <path :d="d" fill="#999999" />
  </svg>
</template>

<script>
export default {
  name: 'showIcon',
  data() {
    return {
      // eslint-disable-next-line vue/max-len
      d: 'M0.787323 6C1.41399 2.58667 4.40532 0 7.99999 0C11.5947 0 14.5853 2.58667 15.2127 6C14.586 9.41333 11.5947 12 7.99999 12C4.40532 12 1.41466 9.41333 0.787323 6V6ZM7.99999 9.33333C8.88405 9.33333 9.73189 8.98214 10.357 8.35702C10.9821 7.7319 11.3333 6.88406 11.3333 6C11.3333 5.11595 10.9821 4.2681 10.357 3.64298C9.73189 3.01786 8.88405 2.66667 7.99999 2.66667C7.11594 2.66667 6.26809 3.01786 5.64297 3.64298C5.01785 4.2681 4.66666 5.11595 4.66666 6C4.66666 6.88406 5.01785 7.7319 5.64297 8.35702C6.26809 8.98214 7.11594 9.33333 7.99999 9.33333ZM7.99999 8C7.46956 8 6.96085 7.78929 6.58578 7.41421C6.2107 7.03914 5.99999 6.53043 5.99999 6C5.99999 5.46957 6.2107 4.96086 6.58578 4.58579C6.96085 4.21071 7.46956 4 7.99999 4C8.53042 4 9.03913 4.21071 9.4142 4.58579C9.78928 4.96086 9.99999 5.46957 9.99999 6C9.99999 6.53043 9.78928 7.03914 9.4142 7.41421C9.03913 7.78929 8.53042 8 7.99999 8Z',
    };
  },
};
</script>

<style scoped></style>
