var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"input__wrapper",attrs:{"tabindex":"1"}},[_c('div',{class:[
      'input',
      {
        'input--with-label': _vm.label || _vm.isCopy,
        'input--error': _vm.error,
        'input--disabled': _vm.disabled,
        'input--select': _vm.type === 'select' || _vm.type === 'date',
        'input--with-icon': _vm.variant === 'password' || _vm.isSelect || _vm.isDate || _vm.isCopy,
        'input--is-open': _vm.isSelect && _vm.isSelectOpen,
        'input--has-value': _vm.type === 'select' && _vm.innerValue,
        'as-dropdown': _vm.isSelect && _vm.variant === 'dropdown',
        'with-display': !!this.optionsDisplay,
        'input--textarea': _vm.variant === 'textarea',
        'input--search': _vm.type === 'search',
        'has--search': _vm.searchInside,
        'input--copy': _vm.isCopy
      } ],attrs:{"tabindex":_vm.isSelect ? 0 : -1},on:{"click":function($event){(_vm.isSelect || _vm.isDate) && !_vm.disabled ? _vm.toggleSelectOpen() : null},"focus":function($event){_vm.isSelectOpen ? _vm.focusInsideSearch() : null},"keyup":function (e) {_vm.filterInside || _vm.searchInside ? _vm.record(e) : null}}},[(_vm.type === 'search' )?_c('svg',{staticClass:"search-icon",attrs:{"fill":"none","height":"14","viewBox":"0 0 14 14","width":"14","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":_vm.searchInputPath,"fill":"#666666"}})]):_vm._e(),(_vm.variant === 'textarea')?_c('textarea',{attrs:{"id":_vm.name,"disabled":_vm.disabled,"name":_vm.name,"placeholder":_vm.label,"rows":"5"},domProps:{"value":_vm.isSelect && _vm.selectedOption ? _vm.selectedOption : _vm.innerValue},on:{"change":_vm.updateInput,"keyup":function($event){return _vm.updateInput($event)}}}):_c('input',{ref:"input",attrs:{"id":_vm.name,"disabled":_vm.disabled || _vm.isSelect || _vm.isDate,"name":_vm.name,"placeholder":_vm.label,"type":_vm.overrideType || _vm.type},domProps:{"value":_vm.isSelect && _vm.selectedOption ? _vm.selectedOption : _vm.isDate ? _vm.formattedDate : _vm.innerValue},on:{"change":_vm.updateInput,"keyup":function($event){return _vm.updateInput($event)}}}),_c('label',{attrs:{"for":_vm.name}},[_vm._v(_vm._s(_vm.label))]),_c('span',{class:{
        input__icon: true,
        'input__icon--is-open': _vm.isSelectOpen,
        'input__icon--click-through': _vm.isSelect || _vm.isDate,
      },on:{"click":function($event){$event.stopPropagation();return _vm.iconClicked.apply(null, arguments)}}},[(_vm.variant === 'password')?[_c('show-icon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.passwordVisible),expression:"!passwordVisible"}]}),_c('hide-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.passwordVisible),expression:"passwordVisible"}]})]:_vm._e(),(_vm.isSelect || _vm.isDate)?_c('dropdown-icon'):_vm._e(),(_vm.isCopy)?_c('svg',{attrs:{"fill":"none","height":"20","viewBox":"0 0 18 20","width":"18","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":_vm.copyIconPath,"fill":"black"}})]):_vm._e()],2),(_vm.isSelect)?[_c('div',{class:['overlay', { 'is-hidden': !_vm.isSelectOpen }],on:{"click":function($event){$event.stopPropagation();return _vm.closeOptionsHandler.apply(null, arguments)}}}),_c('div',{ref:"list",class:['options-list', { 'is-hidden': !_vm.isSelectOpen }],attrs:{"tabindex":"-1"}},[(_vm.searchInside)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filter),expression:"filter"}],ref:"search",staticClass:"search-options",attrs:{"placeholder":'Search ' + this.searchInside,"tabindex":_vm.isSelectOpen ? 0 : -1,"type":"text"},domProps:{"value":(_vm.filter)},on:{"click":function($event){$event.stopPropagation();},"keyup":function($event){$event.stopPropagation();},"input":function($event){if($event.target.composing){ return; }_vm.filter=$event.target.value}}}):_vm._e(),(_vm.optionsDisplay)?[_c('div',{ref:"itemList",staticClass:"list"},_vm._l((Object.entries(_vm.filteredOptions)),function(ref){
      var value = ref[0];
      var display = ref[1];
return _c('div',{key:value,staticClass:"option",attrs:{"tabindex":_vm.isSelectOpen ? 0 : -1,"data-value":value},on:{"keyup":_vm.enterOption}},[_vm._v(" "+_vm._s(display)+" ")])}),0)]:_vm._t("default")],2)]:_vm._e(),(_vm.isDate)?[_c('div',{class:['overlay', { 'is-hidden': !_vm.isSelectOpen }],on:{"click":function($event){$event.stopPropagation();return _vm.closeOptionsHandler.apply(null, arguments)}}}),_c('div',{ref:"date",class:['date-container', { 'is-hidden': !_vm.isSelectOpen }],on:{"click":function($event){$event.stopPropagation();return (function () {}).apply(null, arguments)}}},[(_vm.isSelectOpen)?_c('date-picker',{attrs:{"color":"purple"},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}}):_vm._e()],1)]:_vm._e()],2),_c('transition',{attrs:{"name":"grow"}},[(_vm.error)?_c('div',{staticClass:"error-message"},[_vm._v(" "+_vm._s(this.error)+" ")]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }