import img1 from '@/assets/profile/DJ.png';
import img2 from '@/assets/profile/JF.png';
import img3 from '@/assets/profile/LR.png';

export default {
  all: [
    {
      id: 'fgsawyfcghyegehhe',
      indicator: 'Central Budget',
      country: 'Poland',
      startYear: '1986',
      endYear: '2020',
      lastModified: new Date().toDateString(),
    },
    {
      id: 'fghfghbkyfcghyegehhe',
      indicator: 'Primary Income ',
      country: 'Norway',
      startYear: '1986',
      endYear: '2020',
      lastModified: new Date(2021, 2, 7).toDateString(),
    },
    {
      id: 'fghdjkyfjhyegehhe',
      indicator: 'Current Account to GDP',
      country: 'Norway',
      startYear: '1986',
      endYear: '2020',
      lastModified: new Date().toDateString(),
    },
    {
      id: 'fghertfcghyegehhe',
      indicator: 'Direct Investment (Assets)',
      country: 'Italy',
      startYear: '1986',
      endYear: '2020',
      lastModified: new Date().toDateString(),
    },
    {
      id: 'fgxcvyfcghyegehhe',
      indicator: 'Direct Investment (Liabilities)',
      country: 'Poland',
      startYear: '1986',
      endYear: '2020',
      lastModified: new Date().toDateString(),
    },
    {
      id: 'fghdjkyfcghyegehhe',
      indicator: 'Portfolio Investments (Liabilities)',
      country: 'Norway',
      startYear: '1986',
      endYear: '2020',
      lastModified: new Date().toDateString(),
    },
    {
      id: 'fghdjkyfcghtryhhe',
      indicator: 'Official Reserve Assets',
      country: 'Poland',
      startYear: '1986',
      endYear: '2020',
      lastModified: new Date().toDateString(),
    },
    {
      id: 'fghdjkyfcgghjehhe',
      indicator: 'Population Men ',
      country: 'Italy',
      startYear: '1986',
      endYear: '2020',
      lastModified: new Date().toDateString(),
    },
    {
      id: 'fghdjkyfctyuigehhe',
      indicator: 'Population Women',
      country: 'Poland',
      startYear: '1986',
      endYear: '2020',
      lastModified: new Date().toDateString(),
    },
    {
      id: 'fghdjkyfcgrtyugehhe',
      indicator: 'Urban population',
      country: 'Poland',
      startYear: '1986',
      endYear: '2020',
      lastModified: new Date().toDateString(),
    },
    {
      id: 'fghdjkyfghjyegehhe',
      indicator: 'Urban Population % of total Population ',
      country: 'Norway',
      startYear: '1986',
      endYear: '2020',
      lastModified: new Date().toDateString(),
    },
    {
      id: 'fghdjkyfcghtfghhe',
      indicator: 'Marriages',
      country: 'Italy',
      startYear: '1986',
      endYear: '2020',
      lastModified: new Date().toDateString(),
    },
    {
      id: 'fghdjkyfcgfghgehhe',
      indicator: 'Legal Separations',
      country: 'Poland',
      startYear: '1986',
      endYear: '2020',
      lastModified: new Date().toDateString(),
    },
    {
      id: 'fghdjkyfcghjigehhe',
      indicator: 'Death by Causes (System)',
      country: 'Poland',
      startYear: '1986',
      endYear: '2020',
      lastModified: new Date().toDateString(),
    },
    {
      id: 'fghdjkyfcghyegehhe',
      indicator: 'Urban Population % of total Population 2',
      country: 'Norway',
      startYear: '1985',
      endYear: '2020',
      lastModified: new Date().toDateString(),
    },
    {
      id: 'fghdjkyfcghtyukhhe',
      indicator: 'Marriages 2',
      country: 'Italy',
      startYear: '1985',
      endYear: '2020',
      lastModified: new Date().toDateString(),
    },
    {
      id: 'fghdjkyfcfghgehhe',
      indicator: 'Legal Separations 2',
      country: 'Poland',
      startYear: '1985',
      endYear: '2020',
      lastModified: new Date().toDateString(),
    },
    {
      id: 'fghdjkyfcgfcvbehhe',
      indicator: 'Death by Causes (System) 2',
      country: 'Poland',
      startYear: '1985',
      endYear: '2020',
      lastModified: new Date().toDateString(),
    },
  ],
  published: [
    {
      id: 18,
      indicator: 'Primary Income ',
      country: 'Norway',
      startYear: '1986',
      endYear: '2020',
      lastModified: new Date().toDateString(),
    },
    {
      id: 19,
      indicator: 'Portfolio Investments (Liabilities)',
      country: 'Norway',
      startYear: '1986',
      endYear: '2020',
      lastModified: new Date().toDateString(),
    },
    {
      id: 20,
      indicator: 'Official Reserve Assets',
      country: 'Poland',
      startYear: '1986',
      endYear: '2020',
      lastModified: new Date().toDateString(),
    },
    {
      id: 21,
      indicator: 'Population Men ',
      country: 'Italy',
      startYear: '1986',
      endYear: '2020',
      lastModified: new Date().toDateString(),
    },
    {
      id: 22,
      indicator: 'Urban population',
      country: 'Poland',
      startYear: '1986',
      endYear: '2020',
      lastModified: new Date().toDateString(),
    },
    {
      id: 23,
      indicator: 'Marriages',
      country: 'Italy',
      startYear: '1986',
      endYear: '2020',
      lastModified: new Date().toDateString(),
    },
    {
      id: 24,
      indicator: 'Legal Separations',
      country: 'Poland',
      startYear: '1986',
      endYear: '2020',
      lastModified: new Date().toDateString(),
    },
    {
      id: 25,
      indicator: 'Death by Causes (System)',
      country: 'Poland',
      startYear: '1986',
      endYear: '2020',
      lastModified: new Date().toDateString(),
    },
    {
      id: 26,
      indicator: 'Death without the exact cause',
      country: 'Poland',
      startYear: '1986',
      endYear: '2020',
      lastModified: new Date().toDateString(),
    },
    {
      id: 27,
      indicator: 'Internal Migration for Permanent Residence',
      country: 'Poland',
      startYear: '1986',
      endYear: '2020',
      lastModified: new Date().toDateString(),
    },
  ],
  drafts: [
    {
      id: 28,
      indicator: 'Central Budget',
      country: 'Poland',
      startYear: '1986',
      endYear: '2020',
      lastModified: new Date().toDateString(),
    },
    {
      id: 29,
      indicator: 'Direct Investment (Assets)',
      country: 'Italy',
      startYear: '1986',
      endYear: '2020',
      lastModified: new Date().toDateString(),
    },
    {
      id: 30,
      indicator: 'Portfolio Investments (Liabilities)',
      country: 'Norway',
      startYear: '1986',
      endYear: '2020',
      lastModified: new Date().toDateString(),
    },
    {
      id: 31,
      indicator: 'Population Men ',
      country: 'Italy',
      startYear: '1986',
      endYear: '2020',
      lastModified: new Date().toDateString(),
    },
    {
      id: 33,
      indicator: 'Urban population',
      country: 'Poland',
      startYear: '1986',
      endYear: '2020',
      lastModified: new Date().toDateString(),
    },
    {
      id: 34,
      indicator: 'Death by Causes (System)',
      country: 'Poland',
      startYear: '1986',
      endYear: '2020',
      lastModified: new Date().toDateString(),
    },
    {
      id: 35,
      indicator: 'Death without the exact cause',
      country: 'Poland',
      startYear: '1986',
      endYear: '2020',
      lastModified: new Date().toDateString(),
    },
    {
      id: 36,
      indicator: 'Internal Migration for Permanent Residence',
      country: 'Poland',
      startYear: '1986',
      endYear: '2020',
      lastModified: new Date().toDateString(),
    },
  ],
  deleted: [
    {
      id: 37,
      indicator: 'Death by Causes (System)',
      country: 'Poland',
      startYear: '1986',
      endYear: '2020',
      lastModified: new Date().toDateString(),
    },

  ],
  indicators: [
    {
      id: 38,
      indicator: 'Primary Income',
      category: 'Economy',
      frequency: 'Yearly',
      available: '20',
      lastModified: new Date().toDateString(),
    },
    {
      id: 39,
      indicator: 'Capital Account',
      category: 'Economy',
      frequency: 'Yearly',
      available: '20',
      lastModified: new Date().toDateString(),
    },
    {
      id: 40,
      indicator: 'Agricultural Land',
      category: 'Economy',
      frequency: 'Yearly',
      available: '20',
      lastModified: new Date().toDateString(),
    },
    {
      id: 41,
      indicator: 'Agricultural Budget',
      category: 'Economy',
      frequency: 'Yearly',
      available: '20',
      lastModified: new Date().toDateString(),
    },
    {
      id: 42,
      indicator: 'Life Expectancy - Man',
      category: 'Economy',
      frequency: 'Yearly',
      available: '20',
      lastModified: new Date().toDateString(),
    },
    {
      id: 43,
      indicator: 'Life Expectancy - Woman',
      category: 'Economy',
      frequency: 'Yearly',
      available: '20',
      lastModified: new Date().toDateString(),
    },
    {
      id: 44,
      indicator: 'Marriages',
      category: 'Economy',
      frequency: 'Yearly',
      available: '20',
      lastModified: new Date().toDateString(),
    },
    {
      id: 45,
      indicator: 'Divorces',
      category: 'Economy',
      frequency: 'Yearly',
      available: '20',
      lastModified: new Date().toDateString(),
    },
    {
      id: 46,
      indicator: 'Legal Separation',
      category: 'Economy',
      frequency: 'Yearly',
      available: '20',
      lastModified: new Date().toDateString(),
    },
    {
      id: 47,
      indicator: 'Live Births',
      category: 'Economy',
      frequency: 'Yearly',
      available: '20',
      lastModified: new Date().toDateString(),
    },
    {
      id: 48,
      indicator: 'Value Added Tax',
      category: 'Economy',
      frequency: 'Yearly',
      available: '20',
      lastModified: new Date().toDateString(),
    },
    {
      id: 49,
      indicator: 'Total Population',
      category: 'Economy',
      frequency: 'Yearly',
      available: '20',
      lastModified: new Date().toDateString(),
    },
    {
      id: 50,
      indicator: 'Primary Income',
      category: 'Economy',
      frequency: 'Yearly',
      available: '20',
      lastModified: new Date().toDateString(),
    },
    {
      id: 51,
      indicator: 'Primary Income',
      category: 'Economy',
      frequency: 'Yearly',
      available: '20',
      lastModified: new Date().toDateString(),
    },
    {
      id: 52,
      indicator: 'Primary Income',
      category: 'Economy',
      frequency: 'Yearly',
      available: '20',
      lastModified: new Date().toDateString(),
    },
    {
      id: 53,
      indicator: 'Primary Income',
      category: 'Economy',
      frequency: 'Yearly',
      available: '20',
      lastModified: new Date().toDateString(),
    },
    {
      id: 54,
      indicator: 'Primary Income',
      category: 'Economy',
      frequency: 'Yearly',
      available: '20',
      lastModified: new Date().toDateString(),
    },
    {
      id: 55,
      indicator: 'Primary Income',
      category: 'Economy',
      frequency: 'Yearly',
      available: '20',
      lastModified: new Date().toDateString(),
    },
    {
      id: 56,
      indicator: 'Primary Income',
      category: 'Economy',
      frequency: 'Yearly',
      available: '20',
      lastModified: new Date().toDateString(),
    },
    {
      id: 57,
      indicator: 'Primary Income',
      category: 'Economy',
      frequency: 'Yearly',
      available: '20',
      lastModified: new Date().toDateString(),
    },
  ],
  single: {
    id: 1,
    indicator: 'Central Budget',
    category: 'Economy',
    country: 'Poland',
    startYear: '1986',
    endYear: '2020',
    lastModified: new Date().toDateString(),
    metric: 'United States Dollar',
    frequency: 'Yearly',
    source: 'World Bank',
    link: 'bit.ly/21SHajes10',
    // eslint-disable-next-line
    note: `This is a summarized analysis of the data set. When it is empty, it will not show up on the customer end. It usually talks about the trends of the chart you’re seeing. Sometimes, it could talk about the history, focus on an anomaly or suggest what else to look at to really understand it. It is never a comprehensive tool to understand the data we share.`,
    data: [
      '{ "period": "2000", "value": "" } ',
      '{ "period": "2001", "value": "" } ',
      '{ "period": "2002", "value": "24,055.90" } ',
      '{ "period": "2003", "value": "27,648.40" } ',
      '{ "period": "2004", "value": "33,208.50" } ',
      '{ "period": "2005", "value": "34,617.90" } ',
      '{ "period": "2006", "value": "36,815.50" } ',
      '{ "period": "2007", "value": "49,824.10" } ',
      '{ "period": "2008", "value": "57,177.40" } ',
      '{ "period": "2009", "value": "44,663.00" } ',
      '{ "period": "2010", "value": "47,255.90" } ',
      '{ "period": "2011", "value": "52,701.00" } ',
      '{ "period": "2012", "value": "57,949.30" } ',
      '{ "period": "2013", "value": "47,520.70" } ',
      '{ "period": "2014", "value": "55,332.20" } ',
      '{ "period": "2015", "value": "55,785.40" } ',
      '{ "period": "2016", "value": "57,795.30" } ',
      '{ "period": "2017", "value": "64.378.0" } ',
      '{ "period": "2018", "value": "77,143.70" } ',
      '{ "period": "2019", "value": "78,902.80" } ',
      '{ "period": "2020", "value": "78,498.80" } ',
      '{ "period": "2021", "value": "86,967.40" }',
    ],
    tags: [
      'budget',
      'poland',
      'central budget',
      'dollar',
      'gross domestic product',
      'gdp',
      'world bank data',
    ],
  },
  Customers: [
    {
      id: 'sdf6i8ywhggdhk',
      name: 'Jason Fried',
      email: 'jason.fried@gmail.com',
      joined: '18-02-2021',
      lastSeen: new Date().toDateString(),
      imgUrl: img1,
      status: 'active',
    },
    {
      id: 'sdf6i8y3hggdhk',
      name: 'Jason Fried',
      email: 'jason.fried@gmail.com',
      joined: '18-02-2021',
      lastSeen: new Date().toDateString(),
      imgUrl: img2,
      status: 'active',
    },
    {
      id: 'sdf6i84whggdhk',
      name: 'Jason Fried',
      email: 'jason.fried@gmail.com',
      joined: '18-02-2021',
      lastSeen: new Date().toDateString(),
      imgUrl: img3,
      status: 'active',
    },
    {
      id: 'sdf6i8ywhtgdhk',
      name: 'Jason Fried',
      email: 'jason.fried@gmail.com',
      joined: '18-02-2021',
      lastSeen: new Date().toDateString(),
      imgUrl: img1,
      status: 'active',
    },
    {
      id: 'sdf6i8ywhghdhk',
      name: 'Jason Fried',
      email: 'jason.fried@gmail.com',
      joined: '18-02-2021',
      lastSeen: new Date().toDateString(),
      imgUrl: img2,
      status: 'disabled',
    },
    {
      id: 'sdf6i8ywhgadhk',
      name: 'Jason Fried',
      email: 'jason.fried@gmail.com',
      joined: '18-02-2021',
      lastSeen: new Date().toDateString(),
      imgUrl: img3,
      status: 'disabled',
    },
    {
      id: 'sdf6i8ywhghshk',
      name: 'Jason Fried',
      email: 'jason.fried@gmail.com',
      joined: '18-02-2021',
      lastSeen: new Date().toDateString(),
      imgUrl: img1,
      status: 'active',
    },
    {
      id: 'sdf6i8ywdghshk',
      name: 'Jason Fried',
      email: 'jason.fried@gmail.com',
      joined: '18-02-2021',
      lastSeen: new Date().toDateString(),
      imgUrl: img1,
      status: 'active',
    },
  ],
};
