<template>
  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="16" height="16" viewBox="0 0 30 30" style=" fill:#000000;">
    <path :d="d"></path>
  </svg>
</template>

<script>
export default {
  name: 'showIcon',
  data() {
    return {
      // eslint-disable-next-line vue/max-len
      d: 'M 24.990234 8.9863281 A 1.0001 1.0001 0 0 0 24.292969 9.2929688 L 15 18.585938 L 5.7070312 9.2929688 A 1.0001 1.0001 0 0 0 4.9902344 8.9902344 A 1.0001 1.0001 0 0 0 4.2929688 10.707031 L 14.292969 20.707031 A 1.0001 1.0001 0 0 0 15.707031 20.707031 L 25.707031 10.707031 A 1.0001 1.0001 0 0 0 24.990234 8.9863281 z',
    };
  },
};
</script>

<style scoped></style>
