var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table',{staticClass:"table table__data"},[_c('caption',{staticClass:"hide-text"},[_vm._v(" Activities Table ")]),_c('thead',{staticClass:"table__data__header"},[_c('tr',{class:[
        'table__header-row',
        {
          'table__header-customers': _vm.customers && _vm.customerOption,
          'table__header-customers-option': _vm.customers && !_vm.customerOption,
          'table__header-manageData': _vm.manageData,
        } ]},[_vm._l((_vm.fields),function(field,i){return _c('th',{key:field,class:field,attrs:{"id":field}},[_c('span',{staticClass:"value",staticStyle:{"display":"flex","align-items":"center","cursor":"pointer"}},[(_vm.selectAll && i === 0 && _vm.sorted.length && !_vm.loading)?_c('span',[_c('k-checkbox',{attrs:{"name":"yes","value":"yes","fill":_vm.value.length && _vm.value.length !== _vm.sorted.length ? "fill" : ""},model:{value:(_vm.all),callback:function ($$v) {_vm.all=$$v},expression:"all"}})],1):_vm._e(),_c('span',{staticStyle:{"display":"flex","align-items":"center","cursor":"pointer"},on:{"click":function($event){return _vm.setSortField(field)}}},[_vm._v(" "+_vm._s(_vm.fieldsDisplay ? _vm.fieldsDisplay[field] : field)+" ")])]),_c('span',{staticClass:"caret",class:[{asc: field === _vm.sortField && _vm.direction === 'asc' }],on:{"click":function($event){return _vm.setSortField(field)}}})])}),(_vm.customers)?[_c('th',{staticClass:"view-activity"}),_c('th',{staticClass:"button"})]:_vm._e()],2)]),_c('tbody',{staticClass:"table__data__body"},[(_vm.loading)?_vm._l((20),function(i){return _c('tr',{key:i,class:{'table__row-manage-data': _vm.manageData,
      'table__loading-indicators-table': _vm.indicatorsTable,
      }},_vm._l((_vm.fields),function(field){return _c('td',{key:i + field,class:field,staticStyle:{"height":"20px"},attrs:{"id":field}},[_c('div',{staticClass:"suspense w-70"})])}),0)}):_vm._l((_vm.sorted),function(data){return _c('table-row',{key:data.id || data.createdAt || data.userLastSeen,attrs:{"data":data,"fields":_vm.fields,"customers":_vm.customers,"customerOption":_vm.customerOption,"manageData":_vm.manageData,"changeStatus":"","showStatus":_vm.showStatus},on:{"clickAction":function($event){return _vm.$emit('clickAction', data.id)},"view":function($event){return _vm.$emit('view', data.id, data.fullName)},"changeStatus":function($event){return _vm.$emit('changeStatus', {
            status: data.status === 'enabled' ? 'disabled' : 'enabled',
            id: data.id,
          })}},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }